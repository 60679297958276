<template>
  <VSlickCarousel
    :arrows="true"
    :infinite="false"
    swipe
    swipe-to-slide
    ignore-prefers-reduced-motion
    v-bind="carouselOpts"
  >
    <slot />
  </VSlickCarousel>
</template>

<script>
import { VSlickCarousel } from 'v-slick-carousel'

export default {
  components: { VSlickCarousel },

  props: {
    groupsToShow: { type: Number, default: 2 },
    groupsToScroll: { type: Number, default: 2 },
  },

  computed: {
    carouselOpts () {
      switch (this.$screen.name) {
        case 'sm': return { centerMode: false, groupsToShow: 1.1, groupsToScroll: 1 }
        case 'md': return { centerMode: false, groupsToShow: 2.1, groupsToScroll: 1 }
        default: return { centerMode: false, groupsToShow: this.groupsToShow, groupsToScroll: this.groupsToScroll }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
:deep() {
  .v-slick-list { margin: 0 -12px; }
  .v-slick-arrow.v-slick-disabled { display: none; }
  .v-slick-arrow.prev, .v-slick-arrow.next {
    z-index: 2; top: calc(50% - 27px);
    border: 1px solid #4D4D4D;
    @apply w-10 h-10 bg-white rounded-full;
    &.disabled { display: none !important; }
  }
  .v-slick-arrow.prev:before, .v-slick-arrow.next:before {
    content: ''; display: block; position: absolute; top: 15px; right: 15px; width: 10px; height: 10px; border-top: 2px solid #4D4D4D; border-right: 2px solid #4D4D4D;
  }
  .v-slick-arrow.prev { left: -20px; transform: rotate(-135deg); }
  .v-slick-arrow.next { right: -20px; transform: rotate(45deg); }
}
</style>
